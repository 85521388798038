import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { Pageview, sendDefaultConsentGTM, sendUpdateConsentGTM } from '@/utils/analytics/gtag';
import { getMarketingCookieConsent, getStatisticsCookieConsent } from '@/utils/cookies/cookieConsentRepository';

export default function GoogleAnalytics() {
  const router = useRouter();

  const isMarketingCookiesAllowed = getMarketingCookieConsent();
  const isStatisticsCookiesAllowed = getStatisticsCookieConsent();

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      // Google wants to get the consent status first in the 'default' and 'denied' state for every pageview
      // After this a update consent is sent with the actual consent status based on the cookie
      sendDefaultConsentGTM();

      sendUpdateConsentGTM(isMarketingCookiesAllowed == '1', isStatisticsCookiesAllowed == '1');

      Pageview(url);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events]);

  return (
    <>
      <Script id="google-tag-manager-consent" strategy="afterInteractive">
        {`
          // Define dataLayer and the gtag function.
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}

          gtag('consent', 'default', {
            ad_personalization: "denied",
            ad_user_data: "denied",
            ad_storage: "denied",
            analytics_storage: "denied",
            functionality_storage: "denied",
            personalization_storage: "denied",
            security_storage: "denied",
          });

          // This is done here as useEffect triggers it too much and we only want it on mount
          gtag('consent', 'update', {
            ad_personalization: '${isMarketingCookiesAllowed == '1' ? 'granted' : 'denied'}',
            ad_user_data: '${isMarketingCookiesAllowed == '1' ? 'granted' : 'denied'}',
            ad_storage: '${isMarketingCookiesAllowed == '1' ? 'granted' : 'denied'}',
            analytics_storage: '${isStatisticsCookiesAllowed == '1' ? 'granted' : 'denied'}',
            functionality_storage: "granted",
            personalization_storage: "granted",
            security_storage: "granted",
          });
        `}
      </Script>
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://marketing.youngones.com/marketing.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','dashboard');
          `,
        }}
      />
    </>
  );
}
